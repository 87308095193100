/* .my_prescription_main_table table thead tr th {
  border-right: 1px solid #eaeef0;
}

.my_prescription_main_table table thead tr th {
  background: #219ebc;
  color: #fff;
}

.my_prescription_main_table table thead tr > th:first-child {
  border-radius: 18px 0 0;
}

.my_prescription_main_table table thead tr > th:last-child {
  border-radius: 0 18px 0 0;
}

.my_prescription_main_table tbody tr > * {
  border-right: 1px solid #eaeef0 !important;
  color: #2e3233;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.36px;
} */

.lense_prescription_manually_text_add_inner_div {
  margin-top: 16px;
}
