.need_help_div_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.need_help_div_main_support_text {
  margin-top: 3rem;
}

.need_help_button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
