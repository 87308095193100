.latest_pro_inner_main_div_glass {
  border-radius: 18px;
  border: 1px solid #eaeef0;
  overflow: hidden;
}

.latest_pro_inner_main_div_glass:hover {
  transition:
    box-shadow 0.2s ease-in-out 0s,
    -webkit-box-shadow 0.2s ease-in-out 0s;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 3px 16px 0px;
}

.latest_product_size_add {
  display: flex;
  align-items: center;
}

.latest_product_price_color_tax_info {
  padding: 16px 20px;
}

.latest_product_price_dollar_num_plush_text {
  display: flex;
  align-items: center;
}

.latest_product_num_add_color {
  display: flex;
  align-items: center;
}

.latest_product_inner_price_text_outer_color_add_num {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.latest_product_add_review_list {
  border-bottom: 1px solid #eaeef0;
  padding: 0 20px 16px 20px;
}

.latest_product_all_rating_view_add_depend_product_submit_view p:first-child {
  margin-right: 4px;
}

.latest_inner_product_add_review {
  display: flex;
  align-items: center;
  border-radius: 60px;
  border: 1px solid rgba(255, 183, 3, 0.1);
  background: rgba(255, 183, 3, 0.05);
  width: fit-content;
  padding: 6px;
}

.latest_product_all_rating_view_add_depend_product_submit_view {
  margin-left: 4px;
}

.latest_product_all_rating_view_add_depend_product_submit_view {
  display: flex;
  align-items: center;
}

.latest_pro_inner_main_div_glass .active {
  outline: 2px solid #aaa;
  outline-offset: 2px;
}
