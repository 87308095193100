.shipping_address_flex_contact {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 0.44rem;
}

.shipping_address_li_inner {
  display: flex;
  justify-content: space-between;
}
