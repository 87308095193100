.bill-details-parent {
  flex-direction: column;
  gap: var(--gap-xl);
}

.bill-details-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.shipping_cart_frame-parent54 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xl);
}

.shipping_cart_frame-parent54 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.shipping_cart_frame-parent55 {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shipping_cart_frame-parent55 {
  border-radius: var(--br-base);
  background-color: var(--primary-white);
  border: 1px solid var(--shade-200);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}

.shipping_cart_frame-parent56 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.shipping_cart_frame-parent56 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-base);
}

.shopping-cart-2-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.shopping-cart-2-parent {
  justify-content: space-between;
}

.shopping-cart-2-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.shipping_cart_frame-parent60 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.shipping_cart_frame-parent60 {
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--primary-white);
  border: 1px solid var(--shade-200);
  padding: var(--padding-xl);
}

.single-applied-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
