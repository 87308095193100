/* Create Account */
.create_account_para_text_inner {
  display: flex;
}

.create_account_para_text_inner_main {
  /* margin-left: 4px; */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #2e3233;
}

.create_account .login_right_back_page_main_div {
  margin-bottom: 4rem;
}

.form_continue_text_title_guest_inner_login_link {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #2e3233;
}

/* Login */
.login_right_main_page {
  padding: 48px;
  overflow: auto;
  position: relative;
  z-index: 999999999;
}

.form_input_flex_div_mx_bottom {
  margin-bottom: 16px;
}

.login_text_main_title {
  margin-bottom: 8px;
}

.form_input_forget_text_main_pass_inner_link_tag {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #219ebc;
}

.login_text_main_title_top_div_inner {
  margin-bottom: 27px;
}

.form_get_update_text_data_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.form_input_list_email_pass_other_data {
  margin-bottom: 27px;
}

.form_continue_text_title_main_inner_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form_continue_text_title_guest_inner {
  margin-left: 4px;
}

.form_data_or_text_div_line_main {
  margin: 27px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.form_google_apple_ios_btn_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.form_google_icons_main {
  margin-right: 16px;
  font-size: 24px;
}

.form_google_apple_ios_btn_inner {
  border-radius: 12px;
  border: 1px solid #eaeef0;
  padding: 7px 0;
  width: 100%;
}

.form_google_apple_ios_btn_inner {
  /* margin-bottom: 20px; */
}

.form_line_para_or_main_div_add {
  /* position: relative; */
  text-align: center;
  color: #a7abac;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.form_line_para_or_main_div_add_after {
  /* position: absolute; */
  /* content: ''; */
  border-radius: 61px;
  background: linear-gradient(
    90deg,
    rgb(70 206 239 / 20%) 0%,
    rgba(33, 158, 188, 0) 100%
  );
  width: 9.34rem;
  height: 2px;
  /* top: 50%; */
  /* transform: translate(-50%); */
  /* right: 0; */
  display: flex;
  rotate: -180deg;
}

.form_line_para_or_main_div_add_before {
  /* position: absolute; */
  /* content: ''; */
  border-radius: 61px;
  background: linear-gradient(
    90deg,
    rgb(70 206 239 / 20%) 0%,
    rgba(33, 158, 188, 0) 100%
  );
  width: 9.34rem;
  height: 2px;
  /* top: 50%; */
  /* left: 5rem; */
  display: flex;
}

.form_input_forget_text_main_pass_inner_link_tag {
  color: #219ebc;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 20px;
}

.login_right_inner_outer_info {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.otp_input_text_filed_set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_input_text_filed_set {
  margin-bottom: 24px;
}

.timer_btn_value {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  position: relative;
}

.otp_verify_code_num_inner_second {
  color: #2e3233;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.timer_btn_value > div > span {
  color: #023047;
  font-weight: 600;
  font-family: Poppins;
}

.otp_verification .form_sign_in_btn_main button.see_more_new_btn_inner {
  margin-top: 24px;
}

.timer_btn_value > div > button {
  background: transparent !important;
}
