.frame_information_left_images_open_browse_main {
  padding: 5.78rem 0;
}
.frame_information_left_images_open_browse_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f4f8f9;
  border-radius: 18px;
  border: 1px dashed #eaeef0;
}

.frame_info_text_files_name_browse > div,
.frame_info_text_files_name_browse > div > div,
.video-cam {
  position: relative !important;
}
