.single_vision_stepper_main:hover {
  border-color: rgba(33, 158, 188, 0.3);
  background: linear-gradient(0deg, #f4fafb, #f4fafb);
}

.single_vision_stepper_main_outer {
  padding: 20px 24px 20px 20px;
  cursor: pointer;
}

.single_vision_stepper_main_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single_vision_stepper_inner {
  display: flex;
  align-items: center;
}

.single_vision_lenses_head_text_div {
  display: flex;
  align-items: center;
}

.single_vision_lenses_para_text_div {
  margin-top: 4px;
}

.lens_color_code {
  height: 50px;
  width: 25%;
  background-position: center;
  margin-left: 28px;
}

.single_vision_lenses_head_text_div {
  width: 100%;
}

.single_vision_stepper_inner {
  width: 100%;
}

.single_vision_lenses_head_text_.para_div {
  width: 100%;
}

.single_vision_lenses_head_text_div p:first-child {
  margin-right: 8px;
}
