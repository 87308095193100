.popular_blog_detail h5 {
  max-width: 360px;
  margin: 16px 0 8px 0;
}

.readmore {
  display: flex;
  align-items: center;
}

.readmore img {
  margin-right: 10px;
}

.readmore a {
  margin-left: auto;
}

.blogs_hero {
  margin-bottom: 48px;
}
