/* The `:root` selector in CSS is used to select the root element of the document, which is typically
the `<html>` element. */
:root {
  /* Colors */
  --primary-white: #fff;
  --primary-blue: #219ebc;
  --shade-100: #f4f8f9;
  --shade-200: #eaeef0;
  --color-ghostwhite: #f6f6fc;
  --shade-600: #2e3233;
  --primary-blue: #219ebc;
  --color-cadetblue-100: rgba(33, 158, 188, 0.15);
  --shade-900: #2e3233;
  --shade-200: #eaeef0;
  --body-14-regular-size: 14px;
  --font-size-lg: 18px;
  --shade-600: #2e3233;
  --shade-900: #2e3233;
  --primary-blue: #219ebc;
  --color-cadetblue-100: rgba(33, 158, 188, 0.15);
  --primary-white: #fff;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-xl: 20px;
  --gap-21xl: 40px;
  --gap-2xs: 11px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-7xs: 6px;
  --gap-xs: 12px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-sm: 14px;
  --padding-xl: 20px;
  --padding-xs: 12px;
  --padding-10xs: 3px;
  --padding-8xs: 5px;
  --padding-sm: 14px;
  --padding-3xs: 10px;
  --padding-xl: 20px;
  --br-46xl: 65px;
  --br-31xl: 50px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-xs: 12px;

  /* border radiuses */
  --br-581xl: 600px;
  --br-9xl: 28px;
  --br-base: 16px;
  --br-46xl: 65px;
  --br-57xl: 76px;
  --br-31xl: 50px;

  /* font sizes */
  --body-14-regular-size: 14px;
  --font-size-lg: 18px;
}
