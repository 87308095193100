.review_tab_mira_shah_add_date_num {
  color: #2e3233;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}

.review_tab_main_rating_top_add {
  color: #2e3233;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
}

.review_tab_num_add_inner {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-9xs);
}

.review_tab_frame-parent32,
.review_tab_group,
.review_tab_num_add_inner {
  display: flex;
  justify-content: flex-start;
}

.review_tab_frame-parent32 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--body-14-regular-size);
}

.-review_tab_group {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}

.frame-wrapper1 {
  flex: 1;
}

.review_tab_frame-parent32 {
  margin-left: 10px;
}

.review_tab_inner_outer_frame_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review_tab_m-wrapper5 {
  border-radius: var(--br-46xl);
  background-color: var(--color-cadetblue-100);
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.mira-shah-parent5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  color: var(--shade-900);
}

.review_tab_frame-parent34,
.frame-parent35 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}

.frame-parent35 {
  flex: 1;
  align-items: center;
}

.review_tab_frame-parent34 {
  align-self: stretch;
  align-items: flex-start;
}

.remix-iconslinesystemthumb-parent13 {
  align-items: center;
  color: var(--shade-600);
}

.review_tab_frame-parent36,
.review_tab_frame_parent37,
.remix-iconslinesystemthumb-parent13 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.review_tab_frame_parent37 {
  flex: 1;
  align-items: flex-start;
}

.review_tab_frame-parent36 {
  align-self: stretch;
  align-items: center;
  color: var(--shade-900);
}

.review7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.review7 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xs);
}

.review_tab_num_add_inner_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_tab_div29 {
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--br-31xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review_tab_div29.active {
  background-color: var(--primary-blue);
  color: var(--primary-white);
}

.review_tab_div29:hover {
  background-color: var(--primary-blue);
  color: var(--primary-white);
}

.vuesaxlineararrow-left-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--font-size-lg);
}

.review_tab_bottom_left_pagination
  span.pagination_left_right_arrow_main
  svg
  path {
  stroke: #a7abac;
}

.review_tab_bottom_left_pagination:not(:last-child) {
  margin-right: 12px;
}

.review_tab_bottom_left_pagination:not(:first-child) {
  margin-left: 12px;
}

.review_tab_add_number_list_num_inner:hover p {
  color: #000;
}

.review_tab_add_number_list_num_inner:hover
  button.review_tab_done_btn_add
  span
  svg
  path {
  fill: #219ebc;
}

.review_tab_add_number_list_num_inner:hover
  button.review_tab_done_btn_add
  svg
  g
  path {
  fill: #219ebc;
}

.review_model_open_close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.review_model_inner_head_main {
  margin-bottom: 20px;
}

.rating_main_div {
  margin: 12px 0 32px;
}

.review_model_inner_head .form_input_list_email_pass_other_data {
  margin-bottom: 32px;
}
