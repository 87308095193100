.product_sidebar_inner_frame_size {
  margin-bottom: 12px;
  gap: 12px;
}

.product_sidebar_inner_frame_size:last-child {
  margin-bottom: 0;
}

input[type='checkbox']:checked
  + .product_sidebar_inner_frame_size_inner_sizes
  > .product_sidebar_frame_btn_size
  p {
  color: #fff;
  font-weight: 600;
}

input[type='checkbox']:checked
  + .product_sidebar_inner_frame_size_inner_sizes
  > .product_sidebar_frame_btn_size {
  background: #219ebc;
}

.product_sidebar_main_image_show_frame_for_glass
  .product_sidebar_add_label_inner_main
  input {
  display: none;
}

.product_sidebar_add_label_inner_main input[type='checkbox'] {
  display: none;
}

.product_sidebar_add_label_inner_main:hover .product_latest_diff_color_inner {
  outline-offset: 0.2rem;
  outline: 0.125em solid;
  outline-color: #00000040;
}

label.product_sidebar_add_label_inner_main {
  background: unset;
  height: auto;
  text-indent: 0;
  padding: 0;
  cursor: pointer;
}

.product_sidebar_add_label_inner_main:hover p {
  opacity: 1;
  color: #2e3233;
  font-weight: 600;
}

.product_sidebar_add_label_inner_main:hover
  .product_sidebar_main_list_items_inner_googles {
  border: 2px solid #219ebc;
  opacity: 1 !important;
}

.input[type='checkbox'] {
  display: none;
}

.product_sidebar_color_name_num_add_div {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

input[type='checkbox']:checked
  + .product_sidebar_color_name_num_add_div
  > .product_latest_diff_color_inner {
  outline-offset: 0.2rem;
  outline: 0.125em solid;
  outline-color: #00000040;
}

input[type='checkbox']:checked
  + .product_sidebar_color_name_num_add_div
  > div
  > p {
  color: #000;
  font-weight: 600;
}

.product_latest_diff_color_inner {
  margin-right: 12px;
}

/* input[type='checkbox']:checked
  + .product_sidebar_color_name_num_add_div
  > .product_latest_diff_color_inner {
  outline-offset: 0.2rem;
  outline: 0.125em solid;
  outline-color: #00000040;
} */

.product_sidebar_add_label_inner_main:hover .product_latest_diff_color_inner {
  outline-offset: 0.2rem;
  outline: 0.125em solid;
  outline-color: #00000040;
}

.product_latest_diff_color_name_inner_outer {
  margin-left: 4px;
}
