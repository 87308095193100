.tecnical_information_tab_text6 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 20px;
}

.tecnical_information_tab_tabsdesktop4 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}

.tecnical_information_tab_div21 {
  position: relative;
  letter-spacing: -0.02em;
}

.tecnical_information_tab_div21 {
  font-weight: 500;
  font-family: var(--body-medium);
}

.tecnical_information_tab_hustler1 {
  flex: 1;
  line-height: 20px;
  color: var(--shade-900);
}

.tecnical_information_tab_brand-name-group,
.tecnical_information_tab_frame-parent3 {
  display: flex;
  justify-content: flex-start;
}

.tecnical_information_tab_brand-name-group {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}

.tecnical_information_tab_border_right {
  padding-left: 13px;
}

.tecnical_information_tab_frame-parent3 {
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
}

.tecnical_information_tab_frame-item {
  align-self: stretch;
  position: relative;
  background-color: var(--shade-200);
  width: 2px;
}

.tecnical_information_tab_div32 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
}

.tecnical_information_tab_frame-parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.tecnical_information_tab_brand-name-group p {
  width: 252px;
}
