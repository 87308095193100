/* The CSS code you provided is defining styles for a class called `.lense_prescription_manually_main`. */
.lense_prescription_manually_main {
  display: flex;
  align-items: center;
}

.lense_prescription_manually_main_text_inner {
  margin-left: 48px;
}

.lense_prescription_manually_main {
  border-radius: 18px;
  border: 1px solid #eaeef0;
  padding: 20px 54px;
  cursor: pointer;
}

.lense_prescription_manually_main:not(:last-child) {
  margin-bottom: 24px;
}

.lense_prescription_manually_active_add {
  background: #f4f8f9;
}

.lense_prescription_manually_main:hover {
  background: #f4f8f9;
}

@media (max-width: 1280px) {
  .lense_prescription_manually_main {
    padding: 15px;
  }

  .lense_prescription_manually_main_text_inner {
    margin-left: 12px;
  }
}
