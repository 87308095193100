/* @media only screen and (min-width: 1430px) {}

@media only screen and (min-width: 1600px) {}

@media only screen and (min-width: 1800px) {}

@media only screen and (max-width: 1599px) {}

@media only screen and (max-width: 1429px) {}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 1200px) {
    .specs_categories.process_work {
        display: none;
    }
}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 575px) {}

@media only screen and (max-width: 479px) {}

@media only screen and (max-width: 349px) {}

@media only screen and (max-width: 320px) {} */

@media (320px <=width <=429px) {
}

@media (430px <=width <=539px) {
}

@media (540px <=width <=767px) {
}

@media (768px <=width <=999px) {
}

@media (1000px <=width <=1199px) {
}

@media only screen and (max-width: 1280px) {
  .guide_lens_type_inner_div_main {
    overflow: scroll !important;
  }

  .guide_lens_type_inner_div_main .grid.grid-cols-6.gap-6 {
    min-width: 69rem !important;
  }

  .tecnical_information_tab_brand-name-group p {
    width: 160px;
  }

  .product_details_images_slider_left
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left.thumbnails-swipe-vertical {
    display: none;
  }

  .product_details_images_slider_left img.image-gallery-image {
    height: 31.625rem !important;
    object-fit: contain;
  }

  .process_work_progress_delivery_add_home_main > div {
    width: 31%;
  }

  .process_work_progress_delivery_add_home_main {
    flex-wrap: nowrap !important;
    min-width: 132rem;
  }

  .process_overflow {
    overflow: scroll;
  }

  .process_overflow::-webkit-scrollbar,
  .guide_lens_type_inner_div_main::-webkit-scrollbar {
    display: none;
  }

  .insurance_process_main_card_images {
    margin-bottom: 1rem !important;
  }

  .image-gallery-content.image-gallery-thumbnails-left
    .image-gallery-slide
    .image-gallery-image {
    /* max-height: fit-content !important;
    height: fit-content !important; */
  }

  .footer_end_new_communication {
    flex-wrap: wrap;
  }

  .footer_last_end_main {
    flex-wrap: wrap !important;
  }

  form.footer_end_email_id_inner {
    flex-wrap: wrap;
  }

  .footer_last_product_sitemap_education {
    width: max-content;
  }

  li.contact_us_all_details_user_inner {
    width: 100% !important;
  }

  .contact_us_icons_main {
    height: 54px !important;
    width: 54px !important;
  }

  .sunglasses_blog_detail {
    flex-direction: column;
  }

  ul.my_order_main_details_tab_ul {
    /* min-width: 51.8rem; */
    min-width: max-content;
  }

  .order_status_scroll::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 1196px) {
}

@media only screen and (max-width: 1088px) {
  .tecnical_information_tab_frame-parent3 {
    width: fit-content !important;
  }

  .tecnical_information_tab_frame-parent2 {
    gap: 12px;
    flex-direction: column !important;
  }

  .tecnical_information_tab_frame-item {
    display: none;
  }

  .tecnical_information_tab_border_right {
    padding-left: 0 !important;
  }

  .tecnical_information_tab_brand-name-group p {
    width: fit-content !important;
  }
}

@media only screen and (max-width: 992px) {
  .guide_lens_type_inner_div_card_frame_face_shape {
    padding: 10px !important;
  }

  .guide_frame_face_shape_inner_div_main > * {
    overflow: scroll;
  }

  .guide_frame_face_shape_inner_div_main > div::-webkit-scrollbar {
    display: none;
  }

  .guide_lens_type_inner_div_card_frame_face_shape {
    min-width: max-content;
  }
}

@media only screen and (max-width: 758px) {
  .happy_customer_enjoy_main_info {
    width: 100% !important;
    padding: 10px !important;
  }
}

@media only screen and (max-width: 720px) {
  .guide_eyeglasses_frame_front_lenses_temples {
    padding: 2rem 0 !important;
  }

  input.footer_end_email_id_outer_inner {
    padding: 10px 12px 10px 6px !important;
    width: 100% !important;
  }

  .credit_card_method_title_face_size,
  .existing_eye_glasses_text_title_top,
  .happy_customer_enjoy_dummy_text_lorem_info,
  .specs_categories_head_title_inner_main,
  .guide_eyeglasses_frame_front_inner_images,
  .network_insurance_davis_vision_fil_card_claim_head_text:not(:last-child) {
    margin-bottom: 20px !important;
  }

  span.credit_card_number_one {
    width: 39px !important;
    height: 39px !important;
  }

  .process_work_main_work_progress {
    padding: 10px !important;
  }

  .latest_product_price_color_tax_info,
  .latest_product_add_review_list {
    padding: 10px;
  }

  section.specs_categories {
    padding: 28px 0 !important;
  }

  .product_details_images_slider_left img.image-gallery-image {
    height: 27rem !important;
  }

  .network_insurance_inner_vision_processing_unit.network_insurance_vision_care_processing_unit_card,
  .network_insurance_davis_vision_fil_card_claim_head_text {
    padding: 10px !important;
  }

  .network_insurance_davis_inner_title {
    padding-bottom: 20px !important;
  }

  .network_insurance_davis_vision {
    padding-top: 20px !important;
  }

  .lens_info_main {
    overflow: scroll;
  }

  .lens_info_frame_inner_lens {
    /* width: 349px; */
  }

  .useYourFrameSlider .swiper-pagination {
    position: relative;
    bottom: 0;
  }

  .lens_info_frame_main_lens {
    min-width: 129rem !important;
    display: flex;
  }

  .lens_info_main::-webkit-scrollbar {
    display: none;
  }

  .checkout_main_div {
    padding: 1rem !important;
  }

  .frame_show_images_selected_file_inner {
    padding: 8px !important;
  }

  .frame_information_left_images_open_browse_main_outer {
    margin-bottom: 0 !important;
  }

  .frame_information_right_images_example_img {
    margin-top: 1rem !important;
  }

  .frame_information_right_images_example_img {
    min-width: 20rem !important;
  }

  .single_vision_stepper_main_outer {
    padding: 1rem !important;
  }
}

@media only screen and (max-width: 547px) {
  .specs_categories_inner_main_div > a {
    width: 32%;
  }
}

@media only screen and (max-width: 576px) {
  .shape_grid_list > ul {
    overflow: scroll;
    min-width: 54.8rem;
  }

  .shape_grid_list {
    overflow: scroll;
  }

  .shape_grid_list::-webkit-scrollbar {
    display: none;
  }

  .product_details_images_slider_left
    .image-gallery-content.image-gallery-thumbnails-left
    .image-gallery-slide
    .image-gallery-image {
    max-height: 49vh !important;
  }
}

@media only screen and (max-width: 480px) {
  .footer_last_product_sitemap_education {
    width: 100%;
  }
}

@media only screen and (max-width: 842px) {
  .footer_end_join_community_main_para {
    text-align: start !important;
  }
}

@media only screen and (max-width: 848px) {
  .order_details_track_order_stepper_main {
    overflow-x: scroll;
  }

  .order_details_track_order_stepper_main::-webkit-scrollbar {
    display: none;
  }

  .order_details_track_order_stepper_main > div {
    min-width: 43rem;
  }
}

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 365px) {
  a.h_logo > svg {
    width: 180px;
  }
}

@media (min-width: 576px) and (max-width: 720px) {
  .lens_info_frame_inner_lens {
    height: 336px;
  }

  .process_work_main_work_progress {
    height: 376px;
  }
}

@media (min-width: 392px) and (max-width: 575px) {
  .lens_info_frame_inner_lens {
    height: 248px;
  }

  .process_work_main_work_progress {
    height: 304px;
  }
}

@media (max-width: 391px) {
  .lens_info_frame_inner_lens {
    height: 312px;
  }

  .process_work_main_work_progress {
    height: 352px;
  }
}
