.modal_close_btn_main_top {
  position: relative;
}

.modal_close_btn_main {
  position: absolute;
  left: 1.87rem;
  top: 1.87rem;
  z-index: 9;
  /* right: -18px;
    top: -24px; */
}
